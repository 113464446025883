import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import KeywordTable from './KeywordTable'
import TargetTable from './TargetTable'

import {
  getBidData,
  getBidTargetData,
} from '../../../../redux/actions/campaignDetail'

const BidTab = ({ campaignType, currentAdgroup, isProfitable,
  isNonEndemicAccount, cpcTarget }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)
  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)

  const [isLoading, setIsLoading] = useState(false)
  const [isTargetLoading, setIsTargetLoading] = useState(false)

  useEffect(() => {
    let abortCtrl
    if (campaignType && currentAdgroup && currentDetail) {
      abortCtrl = new AbortController();
      (async () => {
        const params = {
          campaignId: currentDetail.campaign_id,
          adgroupId: currentAdgroup.adgroupid,
          campaignType,
          targetAcos: currentAcos,
          isProfitable,
          isNonEndemicAccount,
          cpcTarget,
        }

        const accessToken = await getAccessTokenSilently()

        if (currentDetail.targeting_type !== 'manual' || currentAdgroup.targetType === 'products') {
          setIsTargetLoading(true)
          await dispatch(getBidTargetData(accessToken, params, abortCtrl.signal))
          setIsTargetLoading(false)
        }

        if (currentDetail.targeting_type !== 'manual' || currentAdgroup.targetType === 'keywords') {
          setIsLoading(true)
          await dispatch(getBidData(accessToken, params, abortCtrl.signal))
          setIsLoading(false)
        }
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [ // eslint-disable-line
    campaignType,
    currentDetail ? currentDetail.campaign_id : null, // eslint-disable-line
    currentAdgroup,
    currentStartDate,
    currentEndDate,
    isProfitable,
  ])

  if (!currentAdgroup) {
    return null
  }

  let keywordTableComponent = null;
  let targetTableComponent = null;
  if (currentDetail.targeting_type !== 'manual' || currentAdgroup.targetType !== 'products') {
    keywordTableComponent = (
      <KeywordTable
        isLoading={isLoading}
        campaignType={campaignType}
      />
    )
  }

  if (currentDetail.targeting_type !== 'manual' || currentAdgroup.targetType === 'products') {
    targetTableComponent = (
      <TargetTable
        isLoading={isTargetLoading}
        campaignType={campaignType}
        campaignId={currentDetail?.campaign_id}
        adgroupId={currentAdgroup?.adgroupid}
      />
    )
  }

  return (
    <>
      {
        keywordTableComponent && (
          <div className="campaign-detail-bid-op campaign-detail-tab">
            <div className="section">
              <div className="section-header">
                <h5>Keywords</h5>
              </div>

              {keywordTableComponent}

            </div>
          </div>
        )
      }

      {
        targetTableComponent && (
          <div className="campaign-detail-bid-op campaign-detail-tab">
            <div className="section">
              <div className="section-header">
                <h4>Targets</h4>
              </div>

              {targetTableComponent}

            </div>
          </div>
        )
      }
    </>
  )
}

export default BidTab
