import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import VideoLink from '../../../CommonComponents/VideoLink'
import CustomTooltip from '../../../CommonComponents/CustomTooltip'
import BidTab from './BidTab'

import AddTargetsModal from '../dashboard/sections/AddTargetsModal'
import AdgroupSelector from '../../AdgroupSelector'

const videoList = [
  { title: 'Bid Optimization Video', url: 'https://www.loom.com/embed/50b32cce01964b1cae9f4c78a5ed49d0' },
]

const BidOPTab = ({ campaignType, isNonEndemicAccount }) => {
  const currentAdGroups = useSelector(state => state.campaignDetail.currentAdGroups)
  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)

  const [currentTab, setCurrentTab] = useState('above')
  const [currentAdgroup, setCurrentAdgroup] = useState(null)
  const [showAddTargeting, setShowAddTargeting] = useState(false)
  const [cpcTarget, setCpcTarget] = useState(0)

  const targetType = useMemo(() => {
    if (campaignType === 'sd') {
      if (currentDetail.tactic === 'T00030') {
        return 'audience'
      }
      return 'product'
    }

    if ((currentAdGroups || []).find(adgroup => adgroup.targetType === 'products')) {
      return 'product'
    }
    return 'keyword'
  }, [currentAdGroups, campaignType, currentDetail.tactic])

  useEffect(() => {
    if (!currentAdGroups || !currentAdGroups.length || currentAdgroup) {
      return
    }
    setCurrentAdgroup(currentAdGroups[0])
  }, [currentAdGroups]) // eslint-disable-line

  const renderTabs = () => {
    let tabList
    if (!isNonEndemicAccount) {
      tabList = [
        {
          value: 'all',
          label: 'All Keywords/Targets',
        },
        {
          value: 'below',
          label: 'Below Target ACOS',
        },
        {
          value: 'above',
          label: 'Above Target ACOS',
        },
      ]
    } else {
      tabList = [
        {
          value: 'all',
          label: 'All Targets',
        },
        {
          value: 'below',
          label: 'Below CPC/VCPM Target',
        },
        {
          value: 'above',
          label: 'Above CPC/VCPM Target',
        },
      ]
    }

    return (
      <div className="tab-list">
          {
            tabList.map((tab) => (
              <button
                key={tab.value}
                type="button"
                className={currentTab === tab.value ? "tab selected" : "tab"}
                onClick={() => { setCurrentTab(tab.value)}}
              >
                { tab.label }
              </button>
            ))
          }
      </div>
    )
  }

  const renderContents = () => {
    let isProfitable
    if (currentTab === 'above') {
      isProfitable = false
    } else if (currentTab === 'below') {
      isProfitable = true
    } else if (currentTab === 'all') {
      isProfitable = ''
    }

    return (
      <BidTab
        campaignType={campaignType}
        currentAdgroup={currentAdgroup}
        isProfitable={isProfitable}
        isNonEndemicAccount={isNonEndemicAccount}
        cpcTarget={cpcTarget}
      />
    )
  }

  return (
    <>
      <div className="campaign-detail-bid-op campaign-detail-tab">
        <div className="tab-info">
          <div className="tab-title">
            Bid Optimizer
            {
              !isNonEndemicAccount && (
                <CustomTooltip placement="right">
                  <p>Bid Optimizer is a tool that suggests a new bid “Genius Bid” based
                    on performance and the Target ACoS that you have set.</p>
                  <p>A minimum of 3 clicks is required before we suggest a “Genius Bid”.</p>
                  <p>
                    Note for Sponsored Product Ads: To match Target ACoS
                    by placement performance, use placement op recommendations instead.
                  </p>
                </CustomTooltip>
              )
            }
          </div>
          <div className="tab-description">
            Review target performance and change bid prices below.
          </div>
          <VideoLink
            videoList={videoList}
            modalTitle='Bid Optimizer'
          />
        </div>
        {renderTabs()}
        <AdgroupSelector
          campaignType={campaignType}
          currentAdgroup={currentAdgroup}
          onAdgroupChange={setCurrentAdgroup}
          onCpcTargetChange={setCpcTarget}
        >
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => {
              setShowAddTargeting(true)
            }}
          >
            Add Targets
          </button>
        </AdgroupSelector>
      </div>

      {renderContents()}

      {
        showAddTargeting && (
          <AddTargetsModal
            show={showAddTargeting}
            currentDetail={currentDetail}
            campaignType={campaignType}
            targetType={targetType}
            onClose={() => {
              setShowAddTargeting(false)
            }}
          />
        )
      }
    </>
  )
}

export default BidOPTab
